import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
      Pnpm: westack {
          tool(id: "pnpm"){
              ...ToolInfo
          }
      }
      K9s: westack {
          tool(id: "k9s"){
              ...ToolInfo
          }
      }
      NGINX_Config: westack {
          tool(id: "nginxconfig-io"){
              ...ToolInfo
          }
      }
      Gravatar: westack {
          tool(id: "gravatar"){
              ...ToolInfo
          }
      }
      Czkawka: westack {
          tool(id: "czkawka"){
              ...ToolInfo
          }
      }
      PostCSS: westack {
          tool(id: "postcss"){
              ...ToolInfo
          }
      }
      Piskel: westack {
          tool(id: "piskel"){
              ...ToolInfo
          }
      }
      Chinese_Guide: westack {
          tool(id: "chinese-copywriting-guidelines"){
              ...ToolInfo
          }
      }
      Uppy: westack {
          tool(id: "uppy"){
              ...ToolInfo
          }
      }
      Prettier: westack {
          tool(id: "prettier"){
              ...ToolInfo
          }
      }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Pnpm.tool} mdxType="Tool" />
    <Tool tool={props.data.K9s.tool} mdxType="Tool" />
    <Tool tool={props.data.NGINX_Config.tool} mdxType="Tool" />
    <Tool tool={props.data.Gravatar.tool} mdxType="Tool" />
    <Tool tool={props.data.Czkawka.tool} mdxType="Tool" />
    <Tool tool={props.data.PostCSS.tool} mdxType="Tool" />
    <Tool tool={props.data.Piskel.tool} mdxType="Tool" />
    <Tool tool={props.data.Chinese_Guide.tool} mdxType="Tool" />
    <Tool tool={props.data.Uppy.tool} mdxType="Tool" />
    <Tool tool={props.data.Prettier.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      